/* components/ContactCard.css */
.contact-card {
  width: 50%;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  max-width: 600px;
  margin: 2rem auto;
  text-align: center;
}

.contact-list {
  width: 50%;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  max-width: 600px;
  margin: 2rem auto;
  text-align: center;
}

.contact-list h3 {
  background-color: #ccc;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.contact-list .list_supp {
  margin-bottom: 3rem;
}

.contact-card h2 {
  font-size: 2rem;
  margin-bottom: 1.5rem;
}

.contact-card p {
  margin-bottom: 1.5rem;
  font-size: 1rem;
  color: #333;
}

.contact-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-group textarea {
  resize: vertical;
}

.button_contact_send {
  background-color: #2d8cf1;
  border: none;
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.button_contact_send:hover {
  background-color: #0056b3;
}
